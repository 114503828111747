import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { clsx } from 'clsx'

import { routes, Link } from '@redwoodjs/router'

import { ChevronRightIcon, ProgressBar, Text } from 'src/atoms'
import { useScreenSize } from 'src/hooks/useResize'

export const QUERY = gql`
  query getProfileProgress {
    profileProgress: getProfileProgress {
      overallProgress
      basicInfo
      industries
      workExperience
      education
      skills
      certifications
      resume
    }
  }
`

export const Loading = () => <></>

export const Empty = () => <div>Empty</div>

export const beforeQuery = (props) => {
  return { variables: props }
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ profileProgress }) => {
  // NOTE: These anchors and their behavior on mobile should be kept in sync with
  // the navigation behavior of `MobileNavigation` in `ProfileEditLayout.tsx`.
  const screenSize = useScreenSize()
  const isScreenSmall = screenSize === 'sm'
  const anchors = [
    {
      href: isScreenSmall
        ? `${routes.profile()}#basicInfo`
        : routes.editBasicInfo(),
      label: 'Basic info',
      completed: profileProgress.basicInfo,
    },
    {
      href: isScreenSmall
        ? `${routes.profile()}#myDocuments`
        : routes.resumes(),
      label: 'My Documents',
      completed: profileProgress.resume,
    },
    {
      href: isScreenSmall
        ? `${routes.profile()}#industries`
        : routes.editIndustries(),
      label: 'Industries',
      completed: profileProgress.industries,
    },
    {
      href: isScreenSmall ? `${routes.profile()}#skills` : routes.editSkills(),
      label: 'Skills',
      completed: profileProgress.skills,
    },
    {
      href: isScreenSmall
        ? `${routes.profile()}#certifications`
        : routes.newCertification(),
      label: 'Certifications',
      completed: profileProgress.certifications,
    },
    {
      href: isScreenSmall
        ? `${routes.profile()}#workExperiences`
        : routes.newWorkExperience(),
      label: 'Work Experience',
      completed: profileProgress.workExperience,
    },
    {
      href: isScreenSmall
        ? `${routes.profile()}#educations`
        : routes.newEducation(),
      label: 'Education',
      completed: profileProgress.education,
    },
  ]

  return (
    <div>
      <Text
        weight="semibold"
        className="text-md mb-2 text-gray-950"
      >{`${profileProgress.overallProgress}% Profile Completion`}</Text>
      <ProgressBar
        progress={profileProgress.overallProgress}
        className="mb-4"
      />
      <ul className="list-unstyled">
        {anchors.map((anchor, indx) => (
          <li key={indx}>
            <Link
              to={anchor.href}
              className="w-100 p-0 ps-1 my-2 flex flex-row items-center justify-between"
            >
              <div className="flex">
                <CheckCircleIcon
                  className={clsx(
                    'me-1.5 size-5',
                    anchor.completed ? 'fill-primary-700' : 'fill-gray-900'
                  )}
                />

                <Text
                  weight="medium"
                  className={clsx(
                    'text-sm hover:text-primary-800',
                    anchor.completed ? 'text-gray-950' : 'text-gray-900'
                  )}
                >
                  {anchor.label}
                </Text>
              </div>
              <ChevronRightIcon className="h-5 w-5 text-gray-600" />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
