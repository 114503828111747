import { useEffect } from 'react'

import mixpanel from 'mixpanel-browser'

import { useLocation } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { logError } from 'src/lib/utils'

export default function GoogleAnalyticsPageViewLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const { pathname } = useLocation()
  const { currentUser } = useAuth()

  useEffect(() => {
    // this is running multiple times, needs a fix
    try {
      const trackData: {
        page: string
        distinct_id?: number
      } = { page: pathname, distinct_id: currentUser?.id }
      mixpanel.track('Page View', trackData)
    } catch (error) {
      logError(error)
    }
  }, [pathname, currentUser])

  useEffect(() => {
    // @ts-expect-error: (Gor) Didn't spend time on fixing this
    if (window && window?.gtag) {
      // @ts-expect-error: (Gor) Didn't spend time on fixing this
      window?.gtag('event', 'page_view', {
        page_location: pathname,
      })
    }
  }, [pathname, currentUser])

  return <>{children}</>
}
