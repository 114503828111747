import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'
import { Select, SelectProps } from 'src/atoms'

type YearsOfExperienceRangesQuery = {
  yearsOfExperienceRanges: {
    id: number
    range: string
  }[]
}

// * BE should return ranges sorted by ordinal in ascending order.

export const QUERY: TypedDocumentNode<YearsOfExperienceRangesQuery> = gql`
  query YearsOfExperienceRangesQuery {
    yearsOfExperienceRanges {
      id
      range
    }
  }
`

// * We do not want to show anything while loading since this is a select input.
export const Loading = () => null

// * Removed `Empty` as it should never be used.

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({
  yearsOfExperienceRanges,
  ...selectProps
}: CellSuccessProps<YearsOfExperienceRangesQuery> & SelectProps) => {
  return (
    <Select
      {...selectProps}
      // * Needed because select values come in as strings and `id` is an integer.
      setFieldValue={(n, v) => selectProps.setFieldValue(n, parseInt(v))}
    >
      {yearsOfExperienceRanges.map((yearsOfExperienceRange) => (
        <option
          key={yearsOfExperienceRange.id}
          value={yearsOfExperienceRange.id}
        >
          {yearsOfExperienceRange.range}
        </option>
      ))}
    </Select>
  )
}
