import { useState } from 'react'

import { PopoverButton as HeadlessPopoverButton } from '@headlessui/react'
import {
  ArrowRightStartOnRectangleIcon,
  BellAlertIcon,
  InformationCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/solid'
import { Bars3Icon } from '@heroicons/react/24/solid'
import Intercom, { show, shutdown } from '@intercom/messenger-js-sdk'
import clsx from 'clsx'

import { routes, useLocation } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import {
  ChevronDownIcon,
  ChevronUpIcon,
  Dialog,
  DialogBody,
  DialogTitle,
  Drawer,
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
  Link,
  Text,
} from 'src/atoms'
import { useAuth } from 'src/auth'
import AvatarFileCell from 'src/components/AvatarFile/AvatarFileCell'
import ReferralContent from 'src/components/ReferralContent/ReferralContent'

const dataFocusClasses =
  'data-[focus]:outline-primary-700 data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2'

const GENERATE_INTERCOM_USER_HASH_MUTATION = gql`
  mutation GenerateIntercomUserHash {
    hash: generateIntercomUserHash
  }
`

type NavigationItem = {
  name: string
  to: string
  current: boolean
  icon: any // TODO: Type?
  children: ChildNavigationItem[]
}

type ChildNavigationItem = {
  name: string
  to: string
  current: boolean
  icon: any // TODO: Type?
  // NOTE: Because we only support one level of nesting, children can't have more children.
}

function DesktopDropdownNavigationItem({
  childrenNavigationItems,
  current,
  name,
}: {
  childrenNavigationItems: ChildNavigationItem[]
  current: boolean
  name: string
}) {
  return (
    <Dropdown>
      <DropdownButton
        aria-current={current ? 'page' : undefined}
        className={clsx(
          current
            ? '!bg-whiteAlpha-300 text-white'
            : // We can't use `text-whiteAlpha-700` because it is a transparent
              // color and causes visual issues with `<ChevronDownIcon>`. We use
              // `text-[#A9ACAD]` instead which is very close to `text-whiteAlpha-700`.
              'hover:bg-whiteAlpha-100 !text-[#A9ACAD] hover:!text-white',
          '!rounded-3xl !px-3 !py-1',
          dataFocusClasses,
          '!gap-1'
        )}
        plain
      >
        {name}
        <ChevronDownIcon className="h-5 w-5" />
      </DropdownButton>
      <DropdownMenu anchor="bottom start">
        {childrenNavigationItems.map((childrenNavigationItem, indx) => {
          return (
            <DropdownItem href={childrenNavigationItem.to} key={indx}>
              <DropdownLabel className="flex items-center gap-3">
                <childrenNavigationItem.icon className="w-5 h-5 opacity-50" />
                {childrenNavigationItem.name}
              </DropdownLabel>
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

function MobileDropdownNavigationItem({
  children,
  icon: Icon,
  name,
}: {
  children: React.ReactNode
  icon: any // TODO: Type?,
  name: string
}) {
  const [showChildren, setShowChildren] = useState(false)

  return (
    <>
      <button
        onClick={() => setShowChildren(!showChildren)}
        className="font-medium text-white flex justify-between items-center px-3 py-2 rounded-md"
      >
        <div className="flex items-center">
          <Icon className="w-5 h-5 me-2" />
          {name}
        </div>
        {showChildren ? (
          <ChevronUpIcon className="w-5 h-5" />
        ) : (
          <ChevronDownIcon className="w-5 h-5" />
        )}
      </button>
      {showChildren && children}
    </>
  )
}

export function TopNavbar({
  navigationItems,
}: {
  navigationItems: NavigationItem[]
}) {
  const auth = useAuth()
  const { logOut, currentUser } = auth

  const { pathname } = useLocation()

  const [referAndEarnDialogOpen, setReferAndEarnDialogOpen] = useState(false)

  const [generateIntercomUserHash] = useMutation(
    GENERATE_INTERCOM_USER_HASH_MUTATION
  )

  const profileNavigationItems: NavigationItem[] = [
    {
      name: 'Notification Settings',
      to: routes.notificationPreferences(),
      icon: BellAlertIcon,
      current: pathname === routes.notificationPreferences(),
      children: [],
    },
  ]

  // NOTE: We don't use the Intercom utils here to open Intercom because we need to
  // generate the user hash before open. Perhaps we can combine this behavior with
  // the existing utils in the future.
  const handleOpenIntercom = () => {
    // Check if Intercom has already been booted.
    if (window.Intercom) {
      show()
    } else {
      generateIntercomUserHash({
        onCompleted: ({ hash }) => {
          Intercom({
            app_id: 'z3dqe1ik', // This could be an environment variable but probably not necessary.
            user_id: currentUser.id.toString(),
            name: currentUser.name,
            email: currentUser.email,
            hide_default_launcher: true,
            user_hash: hash,
          })
          show()
        },
      })
    }
  }

  const handleLogOut = () => {
    // Per Intercom's documentation, we need to call `shutdown` when the user logs out.
    shutdown()

    logOut()
  }

  return (
    <nav className="fixed top-0 z-50 w-full bg-gray-950">
      <div className="max-w-9xl mx-auto px-2 md:px-6 lg:px-8">
        <div className="relative flex h-14 items-center justify-between">
          <div className="flex flex-1 items-center md:items-stretch justify-start">
            <div className="flex flex-shrink-0 items-center">
              <Link
                href={routes.home()}
                className={clsx(dataFocusClasses, 'rounded-lg p-2')}
              >
                <img
                  className="h-7 w-auto"
                  src="/images/logo/laborup_logo_light_text.svg"
                  alt="Laborup logo"
                />
              </Link>
            </div>
            <div className="hidden md:ml-8 md:flex items-center">
              <div className="flex space-x-4">
                {navigationItems.map((navigationItem) => {
                  if (navigationItem.children.length > 0) {
                    return (
                      <DesktopDropdownNavigationItem
                        key={navigationItem.name}
                        childrenNavigationItems={navigationItem.children}
                        current={navigationItem.current}
                        name={navigationItem.name}
                      />
                    )
                  } else {
                    return (
                      <Link
                        key={navigationItem.name}
                        href={navigationItem.to}
                        className={clsx(
                          navigationItem.current
                            ? 'bg-whiteAlpha-300 text-white'
                            : 'hover:bg-whiteAlpha-100 text-whiteAlpha-700 hover:text-white',
                          'rounded-3xl px-3 py-1',
                          dataFocusClasses
                        )}
                        aria-current={
                          navigationItem.current ? 'page' : undefined
                        }
                      >
                        <Text className="text-sm" weight="semibold">
                          {navigationItem.name}
                        </Text>
                      </Link>
                    )
                  }
                })}
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <Dropdown>
              <DropdownButton plain>
                <AvatarFileCell size="size-8" withDropdownIcon />
              </DropdownButton>
              <DropdownMenu anchor="bottom end">
                <DropdownHeader>
                  <Text
                    weight="medium"
                    className="text-sm text-gray-700 pb-1 whitespace-nowrap overflow-hidden text-ellipsis"
                  >{`Signed in as ${currentUser.name}`}</Text>
                  <Text
                    weight="semibold"
                    className="text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis"
                  >
                    {currentUser.email}
                  </Text>
                </DropdownHeader>
                <DropdownDivider />
                {profileNavigationItems.map((profileNavigationItem, indx) => {
                  return (
                    <DropdownItem href={profileNavigationItem.to} key={indx}>
                      <DropdownLabel className="flex items-center gap-3">
                        <profileNavigationItem.icon className="w-5 h-5 opacity-50" />
                        {profileNavigationItem.name}
                      </DropdownLabel>
                    </DropdownItem>
                  )
                })}
                <DropdownItem onClick={handleOpenIntercom}>
                  <DropdownLabel className="flex items-center gap-3">
                    <InformationCircleIcon className="w-5 h-5 opacity-50" />
                    Support
                  </DropdownLabel>
                </DropdownItem>
                <DropdownItem onClick={() => setReferAndEarnDialogOpen(true)}>
                  <DropdownLabel className="flex items-center gap-3">
                    <UsersIcon className="w-5 h-5 opacity-50" />
                    Refer & Earn
                  </DropdownLabel>
                </DropdownItem>
                <DropdownItem onClick={handleLogOut}>
                  <DropdownLabel className="flex items-center gap-3">
                    <ArrowRightStartOnRectangleIcon className="w-5 h-5 opacity-50" />
                    Sign out
                  </DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>

          <Drawer
            side="right"
            className="md:hidden flex"
            panelClassName="bg-gray-950"
            target={
              <Bars3Icon
                className={clsx(
                  'block h-6 w-6 text-white m-2',
                  dataFocusClasses
                )}
                aria-hidden="true"
              />
            }
          >
            <div className="w-full flex items-center gap-4 pt-2 px-4">
              <Link href={routes.profile()}>
                <HeadlessPopoverButton>
                  <AvatarFileCell size="size-10" />
                </HeadlessPopoverButton>
              </Link>

              <div className="py-2 overflow-hidden">
                <Text
                  weight="medium"
                  className="text-sm text-gray-700 truncate"
                >
                  {currentUser.name}
                </Text>
                <Text
                  weight="semibold"
                  className="text-sm text-gray-900 truncate"
                >
                  {currentUser.email}
                </Text>
              </div>
            </div>
            <div className="p-4 flex flex-col gap-4">
              <div className="flex flex-col pb-4 gap-2 border-b border-gray-400">
                {navigationItems.map((navigationItem) => {
                  if (navigationItem.children?.length > 0) {
                    return (
                      <MobileDropdownNavigationItem
                        key={navigationItem.name}
                        icon={navigationItem.icon}
                        name={navigationItem.name}
                      >
                        {navigationItem.children.map((childNavigationItem) => (
                          <Link
                            key={childNavigationItem.name}
                            href={childNavigationItem.to}
                          >
                            <HeadlessPopoverButton
                              className={clsx(
                                childNavigationItem.current && 'bg-gray-900',
                                'rounded-md px-3 py-2 w-full font-medium text-white flex items-center'
                              )}
                              aria-current={
                                childNavigationItem.current ? 'page' : undefined
                              }
                            >
                              <childNavigationItem.icon className="w-5 h-5 me-2" />
                              {childNavigationItem.name}
                            </HeadlessPopoverButton>
                          </Link>
                        ))}
                      </MobileDropdownNavigationItem>
                    )
                  } else {
                    return (
                      <Link key={navigationItem.name} href={navigationItem.to}>
                        <HeadlessPopoverButton
                          className={clsx(
                            navigationItem.current && 'bg-gray-900',
                            'rounded-md px-3 py-2 w-full font-medium text-white flex items-center'
                          )}
                          aria-current={
                            navigationItem.current ? 'page' : undefined
                          }
                        >
                          <navigationItem.icon className="w-5 h-5 me-2" />
                          {navigationItem.name}
                        </HeadlessPopoverButton>
                      </Link>
                    )
                  }
                })}
              </div>
              <div className="flex flex-col pb-2 gap-2">
                {profileNavigationItems.map(
                  ({ name, to, current, icon: Icon }) => (
                    <Link key={name} href={to}>
                      <HeadlessPopoverButton
                        className={clsx(
                          current && 'bg-gray-900',
                          'rounded-md px-3 py-2 w-full font-medium text-white flex items-center'
                        )}
                        aria-current={current ? 'page' : undefined}
                      >
                        <Icon className="w-5 h-5 me-2" />
                        {name}
                      </HeadlessPopoverButton>
                    </Link>
                  )
                )}
                <button onClick={handleOpenIntercom}>
                  <HeadlessPopoverButton className="rounded-md px-3 py-2 font-medium text-white flex w-full items-center">
                    <InformationCircleIcon className="w-5 h-5 me-2" />
                    Support
                  </HeadlessPopoverButton>
                </button>

                <button onClick={() => setReferAndEarnDialogOpen(true)}>
                  <HeadlessPopoverButton className="rounded-md px-3 py-2 font-medium text-white flex w-full items-center">
                    <UsersIcon className="w-5 h-5 me-2" />
                    Refer & Earn
                  </HeadlessPopoverButton>
                </button>
                <button onClick={handleLogOut}>
                  <HeadlessPopoverButton className="rounded-md px-3 py-2 font-medium text-white flex w-full items-center">
                    <ArrowRightStartOnRectangleIcon className="w-5 h-5 me-2" />
                    Sign out
                  </HeadlessPopoverButton>
                </button>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
      <Dialog
        size="4xl"
        open={referAndEarnDialogOpen}
        onClose={() => setReferAndEarnDialogOpen(false)}
      >
        <DialogTitle />
        <DialogBody className="md:px-12 md:py-6">
          <ReferralContent />
        </DialogBody>
      </Dialog>
    </nav>
  )
}
