import React from 'react'

import clsx from 'clsx'

type CustomProps = {
  progress: number
}
export const ProgressBar = React.forwardRef(function ProgressBar(
  {
    progress,
    className,
    ...props
  }: React.HTMLAttributes<HTMLDivElement> & CustomProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      className={clsx('h-1 w-full rounded-lg bg-gray-400', className)}
      {...props}
    >
      <div
        style={{ width: `${progress}%`, transition: 'width 512ms ease-out' }}
        className="bg-primary-700 h-1 rounded-lg"
      ></div>
    </div>
  )
})
