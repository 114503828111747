import { useState, useEffect, useMemo } from 'react'

import { ChevronUpDownIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import debounce from 'debounce'
import { useFormikContext } from 'formik'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import Select, {
  ContainerProps,
  ControlProps,
  ClearIndicatorProps,
  MenuProps,
  components,
} from 'react-select'

import { XMarkIcon } from 'src/atoms'

type Option = {
  label: string
  value: string
}

type StartIcon = {
  selectProps: {
    startIcon?: React.ReactNode
  }
}

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="size-4 text-gray-950" />
    </components.ClearIndicator>
  )
}

const googleMapsApiKey = 'AIzaSyDFSr4C9zBsuwEapYFusK-r34xnjKZ_3d0'

const debounceWait = process.env.NODE_ENV === 'production' ? 128 : 256

const SelectContainer = ({ children, className, ...props }: ContainerProps) => {
  return (
    <components.SelectContainer
      className={clsx('location-select', className)}
      innerProps={{ ...props?.innerProps, 'data-slot': 'control' }}
      {...props}
    >
      {children}
    </components.SelectContainer>
  )
}

const Control = ({ children, ...props }: ControlProps & StartIcon) => {
  const { startIcon, bordered } = props.selectProps

  return (
    <components.Control
      className={clsx(
        bordered && 'shadow-sm',
        'after:pointer-events-none after:absolute after:rounded-lg after:-inset-[1px] after:ring-inset md:after:focus-within:ring-2 md:after:focus-within:ring-primary-700',
        props.className
      )}
      {...props}
    >
      {startIcon}
      {children}
    </components.Control>
  )
}

const Menu = (props: MenuProps) => {
  if (!props?.options?.length) return null
  return <components.Menu {...props}>{props.children}</components.Menu>
}

export const LocationSelect = ({
  placeholder,
  name,
  size = 'small',
  value,
  selectStyles,
  showDropdown,
  className,
  startIcon,
  required,
  bordered = true,
  onChange,
  types,
}) => {
  const [options, setOptions] = useState<Option[]>([])

  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: googleMapsApiKey,
  })

  useEffect(() => {
    const newOptions: Option[] =
      placePredictions?.map?.((prediction) => ({
        label: prediction?.description,
        value: prediction?.description,
      })) || []

    let optionsHaveChanged = false
    newOptions.forEach((option, index) => {
      if (option.label !== options[index]?.label) {
        optionsHaveChanged = true
      }
    })
    if (optionsHaveChanged) {
      setOptions(newOptions)
    }
  }, [placePredictions, options])

  const handleInputChange = useMemo(
    () =>
      debounce((newValue: string) => {
        getPlacePredictions({
          input: newValue,
          types,
          componentRestrictions: { country: 'us' },
        })
      }, debounceWait),
    [getPlacePredictions, types]
  )

  return (
    <Select
      noOptionsMessage={() => (
        <div className={size === 'large' ? 'h-8' : 'h-4'}></div>
      )}
      className={className}
      placeholder={placeholder}
      startIcon={startIcon}
      bordered={bordered}
      components={{
        SelectContainer,
        DropdownIndicator: () => (showDropdown ? <ChevronUpDownIcon /> : null),
        IndicatorSeparator: () => null,
        ClearIndicator,
        Control,
        Menu,
      }}
      name={name}
      isClearable
      isSearchable
      styles={{
        container: (baseStyles) => {
          return {
            ...baseStyles,
            fontWeight: 500,
            fontSize: size === 'large' ? 16 : 16,
            lineHeight: size === 'large' ? '24px' : '20px',
            minHeight: 36,
            width: '100%',
          }
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? 'rgb(var(--color-gray-200))'
              : isSelected
                ? 'rgb(var(--color-primary-700))'
                : isFocused
                  ? 'rgb(var(--color-primary-200))'
                  : 'white',
            color: isDisabled
              ? 'rgb(var(--color-gray-700))'
              : 'rgb(var(--color-gray-950))',
            padding: '0.5rem',
            cursor: isDisabled ? 'not-allowed' : 'default',
          }
        },
        control: (baseStyles, { isFocused, isDisabled }) => {
          return {
            ...baseStyles,
            minHeight: 36,
            height: '100%',
            '&:hover': {},
            borderRadius: bordered ? 8 : 0,
            borderWidth: bordered ? 1 : 0,
            backgroundColor: isDisabled
              ? 'rgb(var(--color-gray-200))'
              : 'white',
            borderStyle: 'solid',
            boxShadow: 'none',
            borderColor: 'rgb(var(--color-gray-500))',
          }
        },
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            color: 'rgb(var(--color-gray-700))',
          }
        },
        ...selectStyles,
      }}
      options={options}
      onInputChange={handleInputChange}
      onChange={onChange}
      value={value}
    />
  )
}

export const LocationSelectFormik = ({
  placeholder,
  name,
  size = 'small',
  value,
  selectStyles,
  showDropdown,
  className,
  bordered = true,
  startIcon,
  required,
  types,
}) => {
  const { setFieldError, errors, setFieldValue } = useFormikContext()
  const [options, setOptions] = useState<Option[]>([])

  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: googleMapsApiKey,
  })

  useEffect(() => {
    const newOptions: Option[] =
      placePredictions?.map?.((prediction) => ({
        label: prediction?.description,
        value: prediction?.description,
      })) || []

    let optionsHaveChanged = false
    newOptions.forEach((option, index) => {
      if (option.label !== options[index]?.label) {
        optionsHaveChanged = true
      }
    })
    if (optionsHaveChanged) {
      setOptions(newOptions)
    }
  }, [placePredictions, options])

  const handleBlur = () => {
    if (!required) return
    if (value?.value) {
      setFieldError(name, '')
    } else {
      setFieldError(name, 'Please enter a valid location')
    }
  }

  const handleFocus = () => {
    if (!required) return
    setFieldError(name, '')
  }

  const handleChange = (option: Option) => {
    setFieldValue(name, option)
  }

  const handleInputChange = useMemo(
    () =>
      debounce((newValue: string) => {
        getPlacePredictions({
          input: newValue,
          types,
          componentRestrictions: { country: 'us' },
        })
      }, debounceWait),
    [getPlacePredictions, types]
  )

  return (
    <Select
      noOptionsMessage={() => (
        <div className={size === 'large' ? 'h-8' : 'h-4'}></div>
      )}
      className={className}
      placeholder={placeholder}
      startIcon={startIcon}
      components={{
        SelectContainer,
        DropdownIndicator: () => (showDropdown ? <ChevronUpDownIcon /> : null),
        IndicatorSeparator: () => null,
        ClearIndicator,
        Control,
        Menu,
      }}
      name={name}
      isClearable
      isSearchable
      styles={{
        container: (baseStyles) => {
          return {
            ...baseStyles,
            fontWeight: 500,
            minHeight: 36,
            fontSize: size === 'large' ? 16 : 16,
            lineHeight: size === 'large' ? '24px' : '20px',
            width: '100%',
          }
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? 'rgb(var(--color-gray-200))'
              : isSelected
                ? 'rgb(var(--color-primary-700))'
                : isFocused
                  ? 'rgb(var(--color-primary-200))'
                  : 'white',
            color: isDisabled
              ? 'rgb(var(--color-gray-700))'
              : 'rgb(var(--color-gray-950))',
            padding: '0.5rem',
            cursor: isDisabled ? 'not-allowed' : 'default',
          }
        },
        control: (baseStyles, { isFocused, isDisabled }) => {
          return {
            ...baseStyles,
            minHeight: 36,
            height: '100%',
            '&:hover': {},
            backgroundColor: isDisabled
              ? 'rgb(var(--color-gray-200))'
              : 'white',
            borderRadius: bordered ? 8 : 0,
            borderWidth: bordered ? 1 : 0,

            borderStyle: 'solid',
            boxShadow: 'none',
            borderColor:
              errors?.[name] && !isFocused
                ? 'rgb(var(--color-red-700))'
                : 'rgb(var(--color-gray-500))',
          }
        },
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            color: 'rgb(var(--color-gray-700))',
          }
        },
        ...selectStyles,
      }}
      options={options}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onInputChange={handleInputChange}
      onChange={handleChange}
      value={value}
      // It is important that this is set to `false` otherwise we run into incorrect
      // validation on touch devices as outlined in the thread below:
      // https://stackoverflow.com/questions/64610967/validation-works-incorrectly-for-react-select-with-formik-on-mobile-device
      blurInputOnSelect={false}
    />
  )
}
