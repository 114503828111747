import ProfileCard from 'src/components/Profile/ProfileCard'

export const QUERY = gql`
  query FindProfileAndIndustries {
    profile: profile {
      id
      firstName
      lastName
      jobStatus
      desiredPosition
      location
      createdAt
      updatedAt
    }
    industries {
      id
      name
      createdAt
      updatedAt
      userId
    }
  }
`

export const Loading = () => <ProfileCard profile={{}} loading />

export const Empty = () => <div>Profile not found</div>

export const Failure = ({ error }) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({ profile }) => {
  return <ProfileCard profile={profile} loading={false} />
}
