import { ComponentPropsWithoutRef, PropsWithChildren } from 'react'

import { ButtonProps as HeadlessButtonProps } from '@headlessui/react'

import { LinkProps } from '../Link/types'

export enum BadgeColor {
  primary = 'primary',
  gray = 'gray',
  green = 'green',
  yellow = 'yellow',
  red = 'red',
}

export enum BadgeSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
}

type Variants = {
  color?: BadgeColor
  size?: BadgeSize
}

export type BadgeProps = PropsWithChildren<ComponentPropsWithoutRef<'span'>> &
  Variants

export type BadgeLinkProps = Variants & LinkProps
export type BadgeButtonProps = Variants & PropsWithChildren<HeadlessButtonProps>
