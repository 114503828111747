import { navigate } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { Text } from 'src/atoms'
import ProfileForm from 'src/components/Profile/ProfileForm'
import { logError } from 'src/lib/utils'

export const QUERY = gql`
  query EditProfile {
    profile: profile {
      firstName
      lastName
      phone
      address
      location
      jobStatus
      desiredPosition
      yoeRangeId
      payBasis
      currentPay
      desiredPay
      createdAt
      updatedAt
    }
    avatarFile: avatarFile {
      id
      url
      createdAt
      updatedAt
      userId
    }
  }
`
export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfileMutation($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      firstName
      lastName
      phone
      address
      location
      jobStatus
      desiredPosition
      yoeRangeId
      payBasis
      currentPay
      desiredPay
      createdAt
      updatedAt
    }
  }
`
export const UPDATE_AVATAR_FILE_MUTATION = gql`
  mutation UpdateAvatarFileMutation($input: UpdateAvatarFileInput!) {
    updateAvatarFile(input: $input) {
      id
      url
      createdAt
      updatedAt
      userId
    }
  }
`

export const Loading = () => (
  <Text weight="semibold" className="text-sm text-gray-700">
    Loading...
  </Text>
)

export const Failure = ({ error }) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({
  profile,
  avatarFile,
  redirectTo,
  onCancel,
  handleSave,
}) => {
  const [updateProfile, { loading, error }] = useMutation(
    UPDATE_PROFILE_MUTATION,
    {
      onCompleted: () => {
        toast.success('Profile updated')
        navigate(redirectTo)
      },
      onError: (error) => {
        logError(error)
        toast.error(error.message)
      },
    }
  )

  const onSave = (input) => {
    const { avatarUrl, ...rest } = input

    updateProfile({ variables: { input: rest } })
  }

  return (
    <>
      <ProfileForm
        profile={profile}
        avatarFile={avatarFile?.url}
        handleSave={handleSave}
        onSave={onSave}
        error={error}
        loading={loading}
        onCancel={onCancel}
      />
    </>
  )
}
