import { useParams } from '@redwoodjs/router'

import NewEndorsementDialogCell from 'src/components/Endorsement/NewEndorsementDialogCell'

/**
 * The purpose of this component is to prevent the `NewEndorsementDialogCell` from being
 * rendered and making API calls when the `endorsementHash` is not present in the URL.
 */
export default function NewEndorsementDialogCellWrapper() {
  const { endorsementHash } = useParams()

  if (!endorsementHash) {
    return null
  }

  return <NewEndorsementDialogCell endorsementHash={endorsementHash} />
}
