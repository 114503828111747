const canVerifyPhoneStorageKey = 'showPhoneVerification'
const appVersionDataStorageKey = 'appVersionData'

const unusedKeys = [
  'test',
  'isFromLoginPage',
  'previousPathname',
  'hasRefreshed',
]

type AppVersionData = {
  version: string
  hasRefreshed: boolean
}

const setAppVersionData = (
  webAppVersionData: AppVersionData
): AppVersionData => {
  localStorage.setItem(
    appVersionDataStorageKey,
    JSON.stringify(webAppVersionData)
  )
  return webAppVersionData
}

const getAppVersionData = (apiVersion: string): AppVersionData | null => {
  const data = localStorage.getItem(appVersionDataStorageKey)

  const appVersionData: AppVersionData | null = data
    ? (JSON.parse(data) as AppVersionData)
    : null

  return appVersionData?.version === apiVersion ? appVersionData : null
}

export const storage = {
  // ! Assuming there is only one api version deployed and its always the latest
  // ! initialize/overwrite the localstorage version to api version
  hasAlreadyRefreshedAppVersion: (version: string): boolean => {
    const appVersionData = getAppVersionData(version)
    return appVersionData
      ? appVersionData.hasRefreshed
      : setAppVersionData({
          version,
          hasRefreshed: false,
        }).hasRefreshed
  },

  // ! Assuming there is only one api version deployed and its always the latest
  // ! initialize/overwrite the localstorage version to api version
  setAppVersionHasRefreshed: (version: string): void => {
    setAppVersionData({
      version,
      hasRefreshed: true,
    })
  },

  clearUnusedKeys: () => {
    unusedKeys.forEach((unusedKey) => localStorage.removeItem(unusedKey))
  },

  getCanVerifyPhone: (): boolean => {
    return localStorage.getItem(canVerifyPhoneStorageKey) === 'true'
  },

  setCanVerifyPhone: (show: boolean): void => {
    localStorage.setItem(canVerifyPhoneStorageKey, show.toString())
  },

  clearAll: () => {
    localStorage.clear()
    sessionStorage.clear()
  },
}
