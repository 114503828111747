import React from 'react'

import { Button as HeadlessButton } from '@headlessui/react'
import { cva } from 'class-variance-authority'
import clsx from 'clsx'

import { TouchTarget } from '../Button/Button'
import { Link } from '../Link/Link'

import {
  BadgeButtonProps,
  BadgeColor,
  BadgeLinkProps,
  BadgeProps,
  BadgeSize,
} from './types'

const badgeVariants = cva(
  'font-semibold rounded-4xl inline-flex items-center gap-x-1 px-2.5 py-1 text-center',
  {
    variants: {
      color: {
        [BadgeColor.primary]:
          'bg-primary-400 text-gray-950 group-data-[hover]:bg-primary-500',
        [BadgeColor.gray]:
          'bg-gray-400 text-gray-950 group-data-[hover]:bg-gray-500',
        [BadgeColor.green]:
          'bg-green-300 text-green-900 group-data-[hover]:bg-green-500/25',
        [BadgeColor.yellow]:
          'bg-yellow-300 text-yellow-950 group-data-[hover]:bg-yellow-500',
        [BadgeColor.red]:
          'bg-red-300 text-red-950 group-data-[hover]:bg-red-500',
      },
      size: {
        [BadgeSize.xs]: 'text-xs',
        [BadgeSize.sm]: 'text-sm',
        [BadgeSize.md]: 'text-md',
      },
    },
    defaultVariants: {
      color: BadgeColor.primary,
      size: BadgeSize.xs,
    },
  }
)

export const Badge = React.forwardRef(
  (
    { color, size, className, ...props }: BadgeProps,
    ref: React.ForwardedRef<HTMLSpanElement>
  ) => {
    return (
      <span
        ref={ref}
        {...props}
        className={clsx(badgeVariants({ color, size }), className)}
      />
    )
  }
)

export const BadgeButton = React.forwardRef(function (
  { color, size, className, children, ...props }: BadgeButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const classes = clsx(
    className,
    'group relative inline-flex rounded-4xl focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-primary-700'
  )

  return (
    <HeadlessButton {...props} className={classes} ref={ref}>
      <TouchTarget>
        <Badge
          className="w-full inline-flex justify-center items-center"
          color={color}
          size={size}
        >
          {children}
        </Badge>
      </TouchTarget>
    </HeadlessButton>
  )
})

export const BadgeLink = React.forwardRef(function (
  { color, size, className, children, ...props }: BadgeLinkProps,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const classes = clsx(
    className,
    'group relative inline-flex rounded-4xl focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-primary-700'
  )

  return (
    <Link {...props} className={classes} ref={ref}>
      <TouchTarget>
        <Badge
          className="w-full inline-flex justify-center items-center"
          color={color}
          size={size}
        >
          {children}
        </Badge>
      </TouchTarget>
    </Link>
  )
})
