import { useMemo } from 'react'

import { Select, SelectProps } from './Select'

const DEFAULT_START_YEAR = 1950
const DEFAULT_END_YEAR = new Date().getFullYear()

type SelectYearProps = SelectProps & {
  endYear?: number
}

export function SelectYear({
  endYear = DEFAULT_END_YEAR,
  ...props
}: SelectYearProps) {
  const yearOptions = useMemo(() => {
    return Array.from({ length: endYear - DEFAULT_START_YEAR + 1 }, (_, i) => ({
      label: (endYear - i).toString(),
      value: (endYear - i).toString(),
    }))
  }, [endYear])

  return (
    <Select {...props}>
      {yearOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}
