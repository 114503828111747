import { useEffect } from 'react'

import { navigate } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import Sentry from 'src/lib/sentry'

const AuthenticatedSentryLayout = ({ children }) => {
  const { currentUser } = useAuth()
  const { isAuthenticated } = useAuth()
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes().login())
    }
    if (currentUser) {
      Sentry.setUser(currentUser)
    }
  }, [currentUser, isAuthenticated])

  return <>{children}</>
}

export default AuthenticatedSentryLayout
