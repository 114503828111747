export const PX_PER_PT = 4 / 3

// Reference: https://www.prepressure.com/library/paper-size/letter
// Letter size is commonly used in US & Canada, while A4 is the standard for rest of world.
export const LETTER_WIDTH_PT = 612
const LETTER_HEIGHT_PT = 792
export const LETTER_WIDTH_PX = LETTER_WIDTH_PT * PX_PER_PT
export const LETTER_HEIGHT_PX = LETTER_HEIGHT_PT * PX_PER_PT

// Reference: https://www.prepressure.com/library/paper-size/din-a4
export const A4_WIDTH_PT = 595
const A4_HEIGHT_PT = 842
export const A4_WIDTH_PX = A4_WIDTH_PT * PX_PER_PT
export const A4_HEIGHT_PX = A4_HEIGHT_PT * PX_PER_PT

export const DEBUG_RESUME_PDF_FLAG: true | undefined = undefined

export const jobTitles = [
  'Machinist',
  'CNC operator',
  'CNC machinist',
  'Welder',
  'MIG welder',
  'TIG welder',
  'Stick welder',
  'Flux-cored arc welder',
  'Underwater welder',
  'Pipeline welder',
  'Fabrication welder',
  'Structural steel welder',
  'Machine operator',
  'Line operator',
  'Production supervisor',
  'Assembly technician',
  'Quality control inspector',
  'Maintenance technician',
  'Process engineer',
  'Manufacturing engineer',
  'Industrial engineer',
  'Mechanical engineer',
  'Electrical engineer',
  'Design engineer',
  'Welding engineer',
  'Structural engineer',
  'Automation engineer',
  'Robotics engineer',
  'Quality engineer',
  'Materials engineer',
  'Product engineer',
  'Aerospace engineer',
  'Aircraft technician',
  'Avionics technician',
  'Aerospace systems engineer',
  'Flight test engineer',
  'Satellite engineer',
  'Propulsion engineer',
  'Aerospace quality inspector',
  'Automotive engineer',
  'Automotive technician',
  'Automotive designer',
  'Vehicle systems engineer',
  'Powertrain engineer',
  'Chassis engineer',
  'Automotive quality inspector',
  'Biomedical engineer',
  'Environmental engineer',
  'Chemical engineer',
  'Metallurgical engineer',
  'Systems engineer',
  'Electrical systems engineer',
  'Mechanical systems engineer',
  'Tool and die maker',
  'Production planner',
  'Materials manager',
  'Logistics coordinator',
  'Supply chain analyst',
  'Robotics technician',
  'Paint technician',
  'Fabrication specialist',
  'Plant manager',
  'Safety coordinator',
  'Assembler',
  'Forklift operator',
  'Press operator',
  'Paint sprayer',
  'Quality inspector',
  'Metal fabricator',
  'Foundry worker',
  'Stamping operator',
  'Molding technician',
  'Body shop worker',
  'Die caster',
  'Rigging technician',
  'Sheet metal worker',
  'Pipefitter',
  'Lathe operator',
  'Grinding operator',
  'Extrusion operator',
  'Welding technician',
  'Electrical assembler',
  'Maintenance mechanic',
  'Tool grinder',
  'Heat treat operator',
  'Inventory clerk',
  'Shipping and receiving clerk',
  'Packaging worker',
  'Scaffold builder',
  'Utility worker',
  'Production assembler',
  'Test technician',
  'Overhaul mechanic',
  'Composite technician',
  'Parts inspector',
  'Finishing worker',
  'Drill press operator',
  'Setup technician',
  'Solderer',
  'Millwright',
  'Plating operator',
  'Turning operator',
  'EDM operator',
  'Precision grinder',
  'Milling machine operator',
  'Bore mill operator',
  'Swiss-style lathe operator',
  'Tool room attendant',
  'Jig bore operator',
  'Gear machinist',
  'CNC lathe machinist',
  'Multi-axis machinist',
  'Waterjet operator',
  'Laser machining operator',
  'Robot cell operator',
  'Quality assurance coordinator',
  'Industrial painter',
  'Safety inspector',
  'Metallurgical technician',
  'CAD technician',
  'CAM programmer',
  'Fixture builder',
  'Material handler',
  'Process control technician',
  'Production coordinator',
  'Control system technician',
  'Fabric cutter',
  'Seamstress/tailor',
  'Pneumatic systems technician',
  'Hydraulic systems technician',
  'Prototype builder',
  'Quality systems engineer',
  'Lean manufacturing engineer',
  'Production control analyst',
  'Inventory control specialist',
  'Supply chain planner',
  'Logistics analyst',
  'Material planner',
  'Distribution coordinator',
  'Fleet manager',
  'Maintenance planner',
  'Reliability engineer',
  'Operations manager',
  'Project manager',
  'Quality manager',
  'R&D engineer',
  'Systems analyst',
  'Network engineer',
]

export const jobTitleOptions = jobTitles.map((title) => ({
  label: title,
  value: title,
}))

export const professionOptions = [
  { label: 'All Professions', value: null },
  { label: 'CNC Machinist', value: 'CNC Machinist' },
  { label: 'CNC Programmer', value: 'CNC Programmer' },
  { label: 'Machinist', value: 'Machinist' },
  { label: 'Mechanic', value: 'Mechanic' },
  { label: 'Boilermaker', value: 'Boilermaker' },
  { label: 'Pipefitter', value: 'Pipefitter' },
  { label: 'Plumber', value: 'Plumber' },
  { label: 'Welder', value: 'Welder' },
  { label: 'Fabricator', value: 'Fabricator' },
  { label: 'Maintenance Technician', value: 'Maintenance Technician' },
  { label: 'HVAC Technician', value: 'HVAC Technician' },
  {
    label: 'Skilled Construction Worker',
    value: 'Skilled Construction Worker',
  },
  { label: 'CAD Designer', value: 'Cad Designer' },
  { label: 'CAD Drafter', value: 'CAD Drafter' },
  { label: 'Machine Operator', value: 'Machine Operator' },
  { label: 'Mechatronics Technician', value: 'Mechatronics Technician' },
  { label: 'Automotive Technician', value: 'Automotive Technician' },
  { label: 'Industrial Electrician', value: 'Industrial Electrician' },
  { label: 'Commercial Truck Driver', value: 'Commercial Truck Driver' },
]

const jobStatuses = [
  'Actively seeking employment',
  'Open to new opportunities',
  'Currently employed',
]

export const jobStatusOptions = jobStatuses.map((status) => ({
  label: status,
  value: status,
}))

const sources = [
  'Social Media',
  'Billboard',
  'Flyer / Mail',
  'Job Board',
  'Referral / Friend',
  'Google Search',
  'Event',
  'Other',
]

export const sourceOptions = sources.map((source) => ({
  label: source,
  value: source,
}))

export const payBasis = ['Hourly', 'Yearly']

export const payBasisOptions = payBasis.map((basis) => ({
  label: basis,
  value: basis,
}))

export const courseFormatOptions = [
  { label: 'Online and In-person', value: null },
  { label: 'In-person', value: 'In-person' },
  { label: 'Online', value: 'Online' },
  { label: 'Hybrid', value: 'Hybrid' },
]

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const monthOptions = months.map((month) => ({
  label: month,
  value: month,
}))
