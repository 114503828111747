import { Banner, BannerDescription, BannerTitle, Card } from 'src/atoms'
import ProfileCardCell from 'src/components/Profile/ProfileCardCell'
import ProfileProgressCell from 'src/components/ProfileProgressCell'

const AccountLayout = ({ children }) => {
  return (
    // NOTE: This `id` is used for navigation on the profile page on mobile.
    // Please be very cautious if you decide to make changes to the id.
    <div className="w-full flex flex-col gap-3" id="profile">
      <Banner>
        <BannerTitle>Important</BannerTitle>
        <BannerDescription>
          Stand out to recruiters by completing your profile
        </BannerDescription>
      </Banner>
      <div className="flex flex-col justify-center gap-3 md:gap-6 md:flex-row">
        <div className="md:w-80 flex flex-col-reverse md:flex-col gap-3 shrink-0">
          {/*
           * NOTE: This `id` is used for navigation on the profile page on mobile.
           * Please be very cautious if you decide to make changes to the id.
           */}
          {/* @ts-expect-error: didn't spend time to fix this */}
          <Card id="basicInfo">
            <ProfileCardCell />
          </Card>
          <Card>
            <ProfileProgressCell />
          </Card>
        </div>
        <div className="mb-4 grow md:max-w-6xl">{children}</div>
      </div>
    </div>
  )
}

export default AccountLayout
