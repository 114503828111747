import { routes } from '@redwoodjs/router'

import { Badge, BadgeColor, Button, PencilIcon, Text } from 'src/atoms'
import AvatarFileCell from 'src/components/AvatarFile/AvatarFileCell'
import { Shimmer } from 'src/components/Shimmer/Shimmer'
import { useScreenSize } from 'src/hooks/useResize'

function capitalizeFirst(string?: string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

const LoadingProfileCard = () => (
  <>
    <div className="flex">
      <Shimmer className="h-10 w-12 me-2" />
      <Shimmer className="h-10" />
    </div>
    <div className="flex w-full items-end justify-between gap-2">
      <Shimmer className="h-10 mt-2" />
    </div>
  </>
)

const ProfileCard = ({ profile, loading }) => {
  const screenSize = useScreenSize()
  const isScreenSmall = screenSize === 'sm'

  if (loading) return <LoadingProfileCard />

  const name = `${capitalizeFirst(profile?.firstName)} ${capitalizeFirst(
    profile?.lastName
  )}`

  const nameComponent = (
    <Text weight="semibold" className="text-md text-gray-950">
      {name}
    </Text>
  )

  const positionComponent = (
    <Text weight="semibold" className="text-sm text-gray-900">
      {profile?.desiredPosition || ''}
    </Text>
  )

  const jobComponent = profile?.jobStatus ? (
    <Badge className="mt-3" color={BadgeColor.gray}>
      {profile.jobStatus}
    </Badge>
  ) : null

  const buttonComponent = (
    <Button href={routes.editBasicInfo()} className="" size="sm" outline isIcon>
      <PencilIcon className="text-gray-950 size-4" />
    </Button>
  )
  return (
    <div className="relative w-full flex flex-col items-center">
      <AvatarFileCell
        size={isScreenSmall ? 'size-32' : 'size-40'}
        className="my-2 md:mt-0"
      />
      {nameComponent}
      {positionComponent}
      <div className="flex items-end gap-3">
        {jobComponent}
        {!isScreenSmall && buttonComponent}
      </div>
      {isScreenSmall && (
        <div className="absolute top-0 right-0">{buttonComponent}</div>
      )}
    </div>
  )
}

export default ProfileCard
