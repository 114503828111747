import { Formik } from 'formik'
import type {
  NewEndorsementDialogQuery,
  NewEndorsementDialogQueryVariables,
} from 'types/graphql'
import * as yup from 'yup'

import { navigate, routes } from '@redwoodjs/router'
import {
  type CellSuccessProps,
  type CellFailureProps,
  type TypedDocumentNode,
  useMutation,
} from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogTitle,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  RequiredAsterisk,
  Select,
  Text,
  Textarea,
} from 'src/atoms'
import { endorsementsUtils } from 'src/lib/utils/endorsements/endorsements'

const schema = yup.object().shape({
  technicalExperienceRating: yup.number().required(),
  leadershipRating: yup.number().required(),
  teamworkRating: yup.number().required(),
  additionalContext: yup.string(),
})

export const QUERY: TypedDocumentNode<
  NewEndorsementDialogQuery,
  NewEndorsementDialogQueryVariables
> = gql`
  query NewEndorsementDialogQuery($endorsementHash: String!) {
    endorseeProfile: findProfileToEndorse(endorsementHash: $endorsementHash) {
      id
      firstName
      lastName
      desiredPosition
    }
  }
`

// TODO: Can we just return empty?
const CREATE_ENDORSEMENT_MUTATION = gql`
  mutation CreateEndorsementMutation($input: CreateEndorsementInput!) {
    createEndorsement(input: $input) {
      technicalExperienceRating
      teamworkRating
      leadershipRating
      additionalContext
      createdAt
    }
  }
`

const EndorsementRatingInput = ({
  label,
  name,
  setFieldValue,
  value,
  error,
}: {
  label: string
  name: string
  setFieldValue: (name: string, value: any) => void
  value: number
  error?: string
}) => {
  return (
    <div className="flex flex-col gap-2">
      <Label>
        {label}
        <RequiredAsterisk />
      </Label>
      <div>
        <Select
          name={name}
          placeholder="Select a rating"
          setFieldValue={(n, v) => setFieldValue(n, parseInt(v))} // Needed because select values come in as strings.
          value={value}
          required
        >
          {[1, 2, 3, 4, 5].map((rating) => {
            return (
              <option key={rating} value={rating}>
                {rating}
              </option>
            )
          })}
        </Select>
        {error && <ErrorMessage message={error} />}
      </div>
    </div>
  )
}

export const Loading = () => null

export const Failure = ({
  error,
}: CellFailureProps<NewEndorsementDialogQueryVariables>) => (
  <Dialog onClose={() => {}} open>
    <DialogTitle showCloseIcon={false}>Something went wrong!</DialogTitle>
    <DialogBody className="flex flex-col gap-6">
      <Card className="!bg-red-200">{error?.message}</Card>
      <Text>
        Please contact us using the <b>Support</b> option in the top-right
        corner if you believe this message was shown to you in error
      </Text>
      <Button className="w-full" onClick={() => navigate(routes.home())}>
        Close
      </Button>
    </DialogBody>
  </Dialog>
)

export const Success = ({
  endorseeProfile,
  endorsementHash,
}: CellSuccessProps<
  NewEndorsementDialogQuery,
  NewEndorsementDialogQueryVariables
> & {
  endorsementHash: string
}) => {
  const [createEndorsement, { loading }] = useMutation(
    CREATE_ENDORSEMENT_MUTATION
  )

  const isValidDesiredPosition = endorsementsUtils.isValidDesiredPosition(
    endorseeProfile.desiredPosition
  )

  const titleText = isValidDesiredPosition
    ? `You are endorsing ${endorseeProfile.firstName} ${endorseeProfile.lastName} as a ${endorseeProfile.desiredPosition}`
    : `You are endorsing ${endorseeProfile.firstName} ${endorseeProfile.lastName}`

  const technicalExperienceLabel = isValidDesiredPosition
    ? `On a scale of 1 to 5, with 1 being poor and 5 being excellent, how would you rate their technical experience as a ${endorseeProfile.desiredPosition}?`
    : 'On a scale of 1 to 5, with 1 being poor and 5 being excellent, how would you rate their technical experience?'

  return (
    <Dialog
      open={true}
      onClose={() => {}} // This prevents the dialog from being manually closed.
    >
      <DialogTitle showCloseIcon={false}>{titleText}</DialogTitle>
      <DialogBody>
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={async (values: yup.InferType<typeof schema>) => {
            createEndorsement({
              variables: {
                input: {
                  endorsementHash,
                  isValidDesiredPosition,
                  technicalExperienceRating: values.technicalExperienceRating,
                  teamworkRating: values.teamworkRating,
                  leadershipRating: values.leadershipRating,
                  additionalContext: values.additionalContext || null,
                },
              },
              onCompleted: () => {
                toast.success('Endorsement submitted!')
                navigate(routes.home()) // This effectively clears the endorsement hash from the URL which closes the modal.
              },
              onError: () => {
                toast.error('Failed to submit endorsement!')
              },
            })
          }}
          initialValues={{
            // @ts-expect-error: Input expects empty string otherwise we get
            // controlled/uncontrolled errors from React.
            technicalExperienceRating: '',
            // @ts-expect-error: Input expects empty string otherwise we get
            // controlled/uncontrolled errors from React.
            teamworkRating: '',
            // @ts-expect-error: Input expects empty string otherwise we get
            // controlled/uncontrolled errors from React.
            leadershipRating: '',
            additionalContext: '',
          }}
        >
          {({ handleChange, handleSubmit, setFieldValue, errors, values }) => (
            <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
              <FieldGroup>
                <Fieldset className="flex flex-col gap-6">
                  <Field>
                    <EndorsementRatingInput
                      error={errors.technicalExperienceRating}
                      label={technicalExperienceLabel}
                      name="technicalExperienceRating"
                      setFieldValue={setFieldValue}
                      value={values.technicalExperienceRating}
                    />
                  </Field>
                  <Field>
                    <EndorsementRatingInput
                      error={errors.teamworkRating}
                      label="On a scale of 1 to 5, with 1 being poor and 5 being
                        excellent, how would you rate their teamwork?"
                      name="teamworkRating"
                      setFieldValue={setFieldValue}
                      value={values.teamworkRating}
                    />
                  </Field>
                  <Field>
                    <EndorsementRatingInput
                      error={errors.leadershipRating}
                      label="On a scale of 1 to 5, with 1 being poor and 5 being
                        excellent, how would you rate their leadership?"
                      name="leadershipRating"
                      setFieldValue={setFieldValue}
                      value={values.leadershipRating}
                    />
                  </Field>
                  <Field>
                    <div className="flex flex-col gap-2">
                      <Label>Anything else you&apos;d like to add?</Label>
                      <div>
                        <Textarea
                          name="additionalContext"
                          onChange={handleChange}
                          value={values.additionalContext}
                        />
                        {errors.additionalContext && (
                          <ErrorMessage message={errors.additionalContext} />
                        )}
                      </div>
                    </div>
                  </Field>
                </Fieldset>
              </FieldGroup>
              <Button className="w-full" disabled={!!loading} type="submit">
                Submit Endorsement
              </Button>
            </form>
          )}
        </Formik>
      </DialogBody>
    </Dialog>
  )
}
