import { useEffect } from 'react'

import { ApolloLink } from '@apollo/client'
import mixpanel from 'mixpanel-browser'
import { register as swiperRegister } from 'swiper/element/bundle'

import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import { Toaster } from '@redwoodjs/web/toast'

import { useScreenSize } from 'src/hooks/useResize'
import Sentry from 'src/lib/sentry'
import { storage } from 'src/lib/storage'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import { env } from './lib/env'
import './mixpanel'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import './styles/output.css'
import './styles/index.css'
swiperRegister()

const checkAppVersionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (!env.webAppVersion) {
      console.error('web app version found')
      return response
    }
    const context = operation.getContext()
    const {
      response: { headers },
    } = context

    if (headers) {
      const apiVersion = headers.get('X-Api-Version')
      if (
        apiVersion &&
        apiVersion !== env.webAppVersion &&
        !storage.hasAlreadyRefreshedAppVersion(apiVersion)
      ) {
        console.info(
          `Api Version: ${apiVersion}, App Version: ${env.webAppVersion} mismatch, Refreshing..`
        )
        storage.setAppVersionHasRefreshed(apiVersion)
        window.location.reload()
      }
    }

    return response
  })
})

const App = () => {
  const screenSize = useScreenSize()

  useEffect(() => {
    storage.clearUnusedKeys()
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <AuthenticatedApp screenSize={screenSize} />
        </AuthProvider>
      </RedwoodProvider>
    </Sentry.ErrorBoundary>
  )
}

const AuthenticatedApp = ({ screenSize }) => {
  const { currentUser } = useAuth()

  // check if the current user is logged in
  useEffect(() => {
    if (currentUser) {
      mixpanel.identify(currentUser.id.toString())
    }
  }, [currentUser])

  return (
    <RedwoodApolloProvider
      useAuth={useAuth}
      graphQLClientConfig={{
        httpLinkConfig: { credentials: 'include' },
        // Assuming redwoodLinks terminate with httpLink, otherwise will have
        // to sort ourselves
        // https://github.com/redwoodjs/redwood/blob/cd4c8bc8c20a0f7c784db5905824698d0dea37cc/packages/web/src/apollo/index.tsx#L132
        link: (rwLinks) => {
          return ApolloLink.from([
            checkAppVersionLink,
            ...rwLinks.map((rwLink) => rwLink.link),
          ])
        },
      }}
    >
      <Routes />
      <Toaster
        toastOptions={{
          className: 'rw-toast',
          duration: 6000,
          position: screenSize === 'sm' ? 'bottom-center' : 'bottom-right',
          success: {
            iconTheme: {
              primary: 'rgb(var(--color-green-700))',
              secondary: 'rgb(var(--color-gray-950))',
            },
          },
          error: {
            iconTheme: {
              primary: 'rgb(var(--color-red-700))',
              secondary: 'rgb(var(--color-gray-950))',
            },
          },
        }}
      />
      {/* <Notification /> */}
    </RedwoodApolloProvider>
  )
}
export default App
