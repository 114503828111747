import { useEffect, useState } from 'react'

import * as formik from 'formik'
import * as yup from 'yup'

import {
  Button,
  ErrorMessage,
  Label,
  Field,
  FieldGroup,
  Fieldset,
  Input,
  RequiredAsterisk,
  Select,
} from 'src/atoms'
import { DesiredPositionAutocompleteInput } from 'src/components/Forms/DesiredPositionAutocompleteInput'
import SalaryPrefix from 'src/components/Forms/SalaryPrefix'
import SalarySuffix from 'src/components/Forms/SalarySuffix'
import SelectYearsOfExperienceRangeCell from 'src/components/SelectYearsOfExperienceRangeCell'
import { LocationSelectFormik } from 'src/components/LocationSelect'
import {
  jobTitleOptions,
  jobStatusOptions,
  payBasisOptions,
} from 'src/lib/constants'

const ProfileForm = (props) => {
  const { Formik } = formik

  const [formattedProfile, setFormattedProfile] = useState(props.profile)

  useEffect(() => {
    if (props.profile) {
      setFormattedProfile({
        ...formattedProfile,
        location: {
          label: props.profile.location,
          value: props.profile.location,
        },
        address: {
          label: props.profile.address,
          value: props.profile.address,
        },
        currentPay:
          props.profile.currentPay === null ? '' : props.profile.currentPay,
        avatarUrl: props.avatarFile,
      })
    }
  }, [formattedProfile, props.avatarFile, props.profile])

  const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    address: yup
      .object({
        label: yup.string().nullable(),
        value: yup.string().nullable(),
      })
      .nullable(),
    location: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .required('Location is required'),
    jobStatus: yup.string().required('Please specify your job status'),
    desiredPosition: yup
      .string()
      .required('Please specify a position of interest'),
    yoeRangeId: yup.number().required('Years of experience is required'),
    payBasis: yup.string().required('Please specify a pay basis'),
    currentPay: yup.number(),
    desiredPay: yup
      .number()
      .positive('Desired pay must be a positive number')
      .required('Desired pay is required'),
  })

  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    location: '',
    jobStatus: '',
    desiredPosition: '',
    yoeRangeId: '',
    payBasis: '',
    currentPay: '',
    desiredPay: '',
  }

  const handleSubmit = (data) => {
    const submitData = {
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.address?.value || null,
      location: data.location?.value,
      jobStatus: data.jobStatus,
      desiredPosition: data.desiredPosition,
      yoeRangeId: data.yoeRangeId,
      payBasis: data.payBasis,
      desiredPay: data.desiredPay,
      // The initial value of `currentPay` is the empty string. Let's make
      // sure we don't send that over the wire as that will cause an error.
      currentPay: data.currentPay === '' ? null : data.currentPay,
      avatarUrl: data.avatarUrl,
    }

    props.onSave(submitData, props?.profile?.id)
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={formattedProfile || initialValues}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <FieldGroup className="flex flex-col gap-1">
            <Fieldset className="flex flex-col gap-6">
              <Field className="flex flex-row flex-nowrap items-end">
                <div className="flex flex-1 flex-col">
                  <Label className="mb-2">
                    First Name
                    <RequiredAsterisk />
                  </Label>
                  <Input
                    placeholder="Enter your first name"
                    required
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    invalid={!!errors.firstName}
                    className=""
                  />
                  {errors.firstName && (
                    <ErrorMessage message={errors.firstName} className="mb-1" />
                  )}
                </div>
              </Field>
              <Field className="flex flex-row flex-nowrap items-end">
                <div className="flex flex-1 flex-col">
                  <Label className="mb-2">
                    Last Name
                    <RequiredAsterisk />
                  </Label>
                  <Input
                    placeholder="Enter your last name"
                    required
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    invalid={!!errors.lastName}
                    className=""
                  />
                  {errors.lastName && (
                    <ErrorMessage message={errors.lastName} className="mb-1" />
                  )}
                </div>
              </Field>
              {/* <Field className="flex flex-row flex-nowrap items-end">
                  <div className="flex flex-1 flex-col">
                    <Label className="mb-2">
                      Phone Number
                      <RequiredAsterisk />
                    </Label>
                    <FormikPhoneInput name="phone" value={values.phone} />
                  </div>
                </Field> */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Field className="flex flex-col flex-wrap">
                  <Label className="!font-semibold mb-2">
                    Desired Work Location
                    <RequiredAsterisk />
                  </Label>
                  <LocationSelectFormik
                    name="location"
                    onChange={handleChange}
                    value={values.location}
                    placeholder="Location where you want to work"
                    types={['(cities)']}
                    setFieldValue={setFieldValue}
                    required
                  />
                  {errors.location && (
                    <ErrorMessage message={errors.location} className="mb-1" />
                  )}
                </Field>
                <Field className="flex flex-col flex-wrap">
                  <Label className="!font-semibold mb-2">Your Address</Label>
                  <LocationSelectFormik
                    name="address"
                    onChange={handleChange}
                    value={values.address}
                    types={['address']}
                    setFieldValue={setFieldValue}
                  />
                  {errors.address && (
                    <ErrorMessage
                      message={errors.address.value}
                      className="mb-1"
                    />
                  )}
                </Field>
              </div>
              <Field className="flex flex-row flex-nowrap items-end">
                <div className="flex flex-1 flex-col">
                  <Label className="mb-2">
                    Desired Position
                    <RequiredAsterisk />
                  </Label>
                  <DesiredPositionAutocompleteInput
                    name="desiredPosition"
                    isClearable
                    isSearchable
                    options={jobTitleOptions}
                    value={values.desiredPosition}
                    placeholder="Ex: CNC Machinist"
                    onChange={(value) =>
                      setFieldValue('desiredPosition', value)
                    }
                  />
                  {errors.desiredPosition && (
                    <ErrorMessage
                      message={errors.desiredPosition}
                      className="mb-1"
                    />
                  )}
                </div>
              </Field>
              <Field className="flex flex-row flex-nowrap items-end">
                <div className="flex flex-1 flex-col">
                  <Label className="mb-2">
                    Years of manufacturing experience
                    <RequiredAsterisk />
                  </Label>
                  <SelectYearsOfExperienceRangeCell
                    name="yoeRangeId"
                    setFieldValue={setFieldValue}
                    value={values.yoeRangeId}
                    required
                  />
                  {errors.yoeRangeId && (
                    <ErrorMessage
                      message={errors.yoeRangeId}
                      className="mb-1"
                    />
                  )}
                </div>
              </Field>
              <Field className="flex flex-row flex-nowrap items-end">
                <div className="flex flex-1 flex-col">
                  <Label className="mb-2">
                    Job Status
                    <RequiredAsterisk />
                  </Label>
                  <Select
                    name="jobStatus"
                    placeholder="Select job status"
                    setFieldValue={setFieldValue}
                    value={values.jobStatus}
                    required
                  >
                    {jobStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  {errors.jobStatus && (
                    <ErrorMessage message={errors.jobStatus} className="mb-1" />
                  )}
                </div>
              </Field>
              <Field className="flex flex-row flex-nowrap items-end">
                <div className="flex flex-1 flex-col">
                  <Label className="mb-2">
                    Are you paid on an hourly or yearly basis?
                    <RequiredAsterisk />
                  </Label>
                  <Select
                    name="payBasis"
                    placeholder="Select a pay basis"
                    setFieldValue={setFieldValue}
                    value={values.payBasis}
                    required
                  >
                    {payBasisOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  {errors.payBasis && (
                    <ErrorMessage message={errors.payBasis} className="mb-1" />
                  )}
                </div>
              </Field>
              <Field className="flex flex-row flex-nowrap items-end">
                <div className="flex flex-1 flex-col">
                  <Label className="mb-2">Current pay</Label>

                  <Input
                    type="number"
                    name="currentPay"
                    placeholder={
                      !values.payBasis ? 'Select a pay basis above' : ''
                    }
                    disabled={!values.payBasis}
                    onChange={handleChange}
                    value={values.currentPay}
                    prefix={values.payBasis ? <SalaryPrefix /> : null}
                    suffix={
                      values.payBasis && (
                        <SalarySuffix payBasis={values?.payBasis} />
                      )
                    }
                  />

                  {errors.currentPay && (
                    <ErrorMessage
                      message={errors.currentPay}
                      className="mb-1"
                    />
                  )}
                </div>
              </Field>
              <Field className="flex flex-row flex-nowrap items-end">
                <div className="flex flex-1 flex-col">
                  <Label className="mb-2">
                    Desired pay
                    <RequiredAsterisk />
                  </Label>
                  <Input
                    prefix={values.payBasis ? <SalaryPrefix /> : null}
                    type="number"
                    name="desiredPay"
                    placeholder={
                      !values.payBasis ? 'Select a pay basis above' : ''
                    }
                    disabled={!values.payBasis}
                    onChange={handleChange}
                    value={values.desiredPay}
                    required
                    suffix={
                      values.payBasis && (
                        <SalarySuffix payBasis={values?.payBasis} />
                      )
                    }
                  />
                  {errors.desiredPay && (
                    <ErrorMessage
                      message={errors.desiredPay}
                      className="mb-1"
                    />
                  )}
                </div>
              </Field>
            </Fieldset>
            <div className="w-100 text-color-950 flex flex-col">
              <Button type="submit">Save</Button>
            </div>
          </FieldGroup>
        </form>
      )}
    </Formik>
  )
}

export default ProfileForm
