/**
 * A variation of the `<ProgressBar>` component in which progress is
 * represented as `n` equal-width bars as opposed to a single bar.
 */

import React from 'react'

import clsx from 'clsx'

type CustomProps = {
  currentStep: number
  totalSteps: number
}

export const DisjointProgressBar = React.forwardRef(
  function DisjointProgressBar(
    {
      currentStep,
      totalSteps,
      className,
      ...props
    }: React.HTMLAttributes<HTMLDivElement> & CustomProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) {
    return (
      <div
        ref={ref}
        className={clsx('h-1 w-full flex gap-2', className)}
        {...props}
      >
        {Array.from({ length: totalSteps }).map((_, index) => {
          return (
            <div className="w-full bg-gray-400" key={index}>
              <div
                style={{
                  transition: 'width 512ms ease-out',
                  width: currentStep < index ? '0' : '100%',
                }}
                className="bg-primary-700 h-full"
              ></div>
            </div>
          )
        })}
      </div>
    )
  }
)
