import { StarIcon } from '@heroicons/react/24/solid'

import { Text } from 'src/atoms'

const testimonials = [
  {
    src: 'images/testimonials/testimonial_image_1.webp',
    name: 'Experienced Maintenance Tech ',
    occupation: 'Knoxville, TN',
    review:
      "I recently created my Laborup profile and within 2 weeks I had multiple offers. I landed a job that pays 20% more and has a better shift. I'm glad something like this exists for us blue-collar folks!",
  },
  {
    src: 'images/testimonials/machinist_male.webp',
    name: 'Senior CNC Machinist',
    occupation: 'Oak Ridge, TN',
    review:
      "I was a little skeptical at first, but damn was I wrong. The platform itself is easy to use, and the people behind the platform are easy to reach and help you along the way. It's nice to see that the folks that built this are from the South and worked in manufacturing. They get us!",
  },
]

const Testimonial = ({ step }) => {
  return (
    <div className="flex items-center flex-col gap-7 p-10">
      <div className="flex flex-col items-center">
        <img
          width={100}
          height={100}
          src={testimonials[step].src}
          alt="person giving testimonial"
          className="mb-4"
        />
        <Text weight="bold" className="text-lg text-white">
          {testimonials[step].name}
        </Text>
        <Text weight="medium" className="text-regular text-white">
          {testimonials[step].occupation}
        </Text>
      </div>
      <div className="max-w-md flex flex-col items-center gap-4">
        <div className="flex gap-1">
          <StarIcon className="text-primary-700 w-5 h-5" />
          <StarIcon className="text-primary-700 w-5 h-5" />
          <StarIcon className="text-primary-700 w-5 h-5" />
          <StarIcon className="text-primary-700 w-5 h-5" />
          <StarIcon className="text-primary-700 w-5 h-5" />
        </div>
        <Text weight="normal" className="text-xl text-white text-center">
          {testimonials[step].review}
        </Text>
      </div>
    </div>
  )
}

export default Testimonial
