import React from 'react'

import clsx from 'clsx'

import { Text } from '../Text/Text'

type Props = {
  className?: string
  message: React.ReactNode
}

export const ErrorMessage = ({ message, className }: Props) => (
  <Text weight="medium" className={clsx(className, 'text-sm text-red-800')}>
    {message}
  </Text>
)
